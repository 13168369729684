import { $themeConfig } from '@themeConfig'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    schoolsList: [],
    selectedInstance: 1,
  },
  getters: {
    getSchoolsList: state => state.schoolsList,
    getSelectedInstance: state => state.selectedInstance,
  },
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    SET_SCHOOLS_LIST(state, val) {
      state.schoolsList = val
    },
    SET_SELECTED_INSTANCE(state, val) {
      state.selectedInstance = val
    },
  },
  actions: {
    async fetchSchoolsList({ commit }, queryParams) {
      try {
        const response = await axios.get('/auth/schools', { params: queryParams })

        const schoolsList = response.data.data.reduce((arr, item) => {
          arr.push({ id: item.id, label: item.name })
          return arr
        }, [])

        commit('SET_SCHOOLS_LIST', schoolsList)
        return response
      } catch (error) {
        return error
      }
    },
  },
}
